import {Injectable} from '@angular/core';
import {BookingDataService} from '../../core/booking-data/booking-data.service';
import {Campaign} from './campaign';
import {BaseServiceConfigurationService} from '../services/base-service-configuration.service';
import {BookingScopedService} from '../booking/booking-scoped.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private booking: BookingScopedService, private data: BookingDataService, private base: BaseServiceConfigurationService, private translate: TranslateService) {
  }

  get applicableCampaign(): Campaign | null {
    const campaignData = this.data.data.campaign;
    return campaignData ? new Campaign(campaignData, this.base.serviceConfiguration, this.booking.booking, this.translate) : null;
  }
}
