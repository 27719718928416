import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {BookingDataLoaderService} from './booking-data-loader.service';
import {BookingDataBody} from './booking-data-body';
import {BookingDataService} from "./booking-data.service";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class BookingDataResolver {

    constructor(private loader: BookingDataLoaderService, protected dataService: BookingDataService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BookingDataBody> {
        const key = route.params.key;
        console.log(`Loading data for booking with key ${key}`);
        return this.loader.load(key).pipe(tap(bDataBody => this.dataService.data = bDataBody));
    }
}
