import {Injectable, OnDestroy} from '@angular/core';
import {BookingDataBody} from './booking-data-body';
import {MonoTypeOperatorFunction} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingDataService implements OnDestroy {
  private _data: BookingDataBody | null = null;

  set data(data: BookingDataBody) {
    this._data = data;
  }

  get data(): BookingDataBody {
    return JSON.parse(JSON.stringify(this._data));
  }

  update(): MonoTypeOperatorFunction<BookingDataBody> {
    return source => source.pipe(tap(data => {
      return this.data = data;
    }));
  }

  ngOnDestroy(): void {
  }
}
