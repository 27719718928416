import {Injectable} from '@angular/core';
import {BookingDataBody} from '../../core/booking-data/booking-data-body';
import {MonoTypeOperatorFunction, Observable, of} from 'rxjs';
import {BookingService} from './booking.service';
import {BookingDataService} from '../../core/booking-data/booking-data.service';
import {Package} from '../services/package';
import {Service} from '../services/service';
import {finalize, tap} from 'rxjs/operators';
import {Booking} from './booking';
import {BookingStoreService} from "./booking-store.service";

@Injectable({
  providedIn: 'root',
})
export class BookingScopedService {
  private _loading = false;

  constructor(private bookings: BookingService, private bookingStoreService: BookingStoreService, private bookingData: BookingDataService) {
  }

  togglePackage(pack: Package): Observable<BookingDataBody> {
      const value = !pack.selected;

      const serviceStates = pack.services
        .filter(service => service.selected !== value)
        .reduce((all, service) => {
          all[service.key] = value;
          return all;
        }, {} as { [key: string]: boolean });

      return this.updateServices(serviceStates);
  }

  toggleService(service: Service): Observable<BookingDataBody> {
    return this.updateServices({
      [service.key]: !service.selected
    });
  }

  private updateServices(serviceStates: { [key: string]: boolean }): Observable<BookingDataBody> {
    const uuid = this.bookingUuid;

    if (uuid) {
      this.startLoading();
      return this.bookings.bulkServiceChange(uuid, serviceStates).pipe(
        this.bookingData.update(),
        tap(b => this.bookingStoreService.set(b)),
        this.finishedLoading());
    } else {
      return of({booking: null, ical: null, near: null, campaign: null});
    }
  }

  get booking(): Booking {
    return new Booking(this.bookingData.data.booking);
  }

  get bookingUuid(): string {
    const uuid = this.bookingData.data.booking?.uuid;

    if (!uuid) {
      console.error('Could not get booking UUID from data', this.bookingData.data);
    }

    return uuid;
  }

  get loading(): boolean {
    return this._loading;
  }

  private startLoading(): void {
    this._loading = true;
  }

  private finishedLoading(): MonoTypeOperatorFunction<BookingDataBody> {
    return source => {
      return source.pipe(
        finalize(() => this._loading = false)
      );
    };
  }
}
