import { Emitter } from './emitter';
import { Service } from './service';

export class Package {
  private _editable = true;
  private emitter = new Emitter<Package>();
  private bulkChangeInProgress = false;

  constructor(
    readonly key: string,
    readonly price: number,
    readonly includedServices: Service[]
  ) {
    const observer = (srv: Service) => this.serviceChange(srv);
    includedServices.forEach((service) => {
      service.observe(observer);
    });
  }

  set editable(editable: boolean) {
      this.includedServices.forEach(s => s.editable = editable);
      this._editable = editable;
  }

  get editable(): boolean {
      return this._editable;
  }

  set selected(sel: boolean) {
    if (this.editable) {
      this.bulkChangeInProgress = true;
      this.includedServices.forEach((s) => (s.selected = sel));
      this.bulkChangeInProgress = false;
      this.emitter.emit(this);
    }
  }

  get selected(): boolean {
    return this.includedServices.every((service) => service.selected);
  }

  get effectivePrice(): number {
    if (this.selected) {
      return this.price;
    }

    return this.includedServices
      .map((s) => s.effectivePrice)
      .reduce((sum, item) => (sum += item), 0);
  }

  get ordinaryPrice(): number {
    return this.includedServices
      .map((s) => s.regularPrice)
      .reduce((sum, regularPrice) => (sum += regularPrice), 0);
  }

  private serviceChange(service: Service) {
    if (!this.bulkChangeInProgress) {
      this.emitter.emit(this);
    }
  }

  translationKey(suffix?: string) {
    if (suffix) return `SERVICES.${this.key}_${suffix}`;

    return `SERVICES.${this.key}`;
  }

  observe(callback: { (pkg: Package): void }): void {
    this.emitter.observe(callback);
  }
}
